<template>
  <v-container>
    <div class="d-flex flex-column flex-grow-1">
      <div class="d-flex flex-row">
        <PageTitle text="Products" class="font font-weight-bold" />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              @click.stop="
                $store.dispatch('products/list', {
                  page: options.page,
                  itemsPerPage: options.itemsPerPage
                })
              "
              v-on="on"
              small
              fab
              icon
              color="primary"
              class="mt-2 mr-3"
            >
              <i class="material-icons-outlined">refresh</i>
            </v-btn>
          </template>
          <span class=" font font-weight-medium">Refresh Page</span>
        </v-tooltip>
      </div>
    </div>
    <div class="buttonText ml-1" id="result">
      <h4
        style="margin-top: 20px; margin-left: 10px"
        class="font blue-grey--text"
      >
        All Products
      </h4>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="products"
              item-key="product"
              :server-items-length="pagination.total"
              :options.sync="options"
              loading-text="Loading... Please wait"
              :loading="isListLoading"
              class="box-shadow-light font font-weight-medium"
            >
              <template #item.name="{item}">
                <v-chip color="primary" label small>
                  <span class="font font-weight-medium font-size-sm">{{
                    item.name
                  }}</span></v-chip
                >
              </template>
              <template #item.prices="{item}">
                <v-chip label small>
                  <span class="font font-weight-bold font-size-sm">{{
                    item.prices
                  }}</span></v-chip
                >
              </template>
              <template #item.meterReading="{item}">
                <span
                  class="font font-weight-bold font-size-sm"
                  v-if="item.isOpeningMeterAvailable"
                  >{{ item.isOpeningMeterAvailable.meterReading }} L</span
                ><span v-else class="font font-weight-bold font-size-sm"
                  >0 L</span
                >
              </template>
            </v-data-table>
          </div>
        </template>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog1" width="400px">
      <v-form ref="form">
        <v-card>
          <v-card-title>
            <span class="ml-6 subtitle-1 font-weight-bold blue-grey--text"
              >Create Product</span
            >
            <v-spacer></v-spacer>
            <v-progress-linear
              v-show="progress"
              class="mx-6"
              indeterminate
              color="cyan"
            ></v-progress-linear>
          </v-card-title>
          <v-card-actions>
            <v-spacer
              ><v-form ref="form" class="px-3">
                <v-col>
                  <v-row>
                    <v-text-field
                      dense
                      outlined
                      label="Name"
                      ref="name"
                      class="mx-5 px-2 required"
                      v-model="name"
                      :rules="[rules.requiredName]"
                    >
                    </v-text-field>

                    <v-text-field
                      dense
                      outlined
                      label="Description"
                      ref="description"
                      class="mx-5 px-2 required"
                      v-model="description"
                      :rules="[rules.requiredDes]"
                    >
                    </v-text-field
                  ></v-row>
                  <v-row> </v-row>
                </v-col>
                <v-row>
                  <v-spacer />
                  <v-btn
                    small
                    class=" px-1 mt-n5 mb-4 ml-10"
                    text
                    @click="dialog1 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    small
                    class="blue  px-3 mt-n5 mb-4 ml-5 mr-10 white--text"
                    text
                    @click.stop="add"
                  >
                    Save
                  </v-btn>
                </v-row>
              </v-form></v-spacer
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <div class="text-center">
      <v-dialog v-model="dialogDelete" max-width="290">
        <v-card>
          <v-progress-linear
            v-show="progress"
            indeterminate
            color="cyan"
          ></v-progress-linear>
          <v-card-title class="blue-grey--text">
            <h5>
              Delete this product?
            </h5>
          </v-card-title>

          <v-card-text>
            <h4>{{ productInfo.name }}</h4></v-card-text
          >

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="darken-1" text @click="dialogDelete = false">
              No
            </v-btn>

            <v-btn color="red darken-1" text @click="deleteProduct()">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-dialog v-model="dialogUpdate" width="400px">
      <v-card ref="form">
        <v-card-title>
          <span class="ml-6 subtitle-1 font-weight-bold blue-grey--text"
            >Update Product</span
          >
          <v-spacer></v-spacer>
          <v-progress-linear
            v-show="progress"
            class="mx-6"
            indeterminate
            color="cyan"
          ></v-progress-linear>
        </v-card-title>
        <v-card-actions>
          <v-spacer
            ><v-form class="px-3">
              <v-col>
                <v-row>
                  <v-text-field
                    dense
                    outlined
                    label="Name"
                    ref="name"
                    class="mx-5 px-2 required"
                    v-model="itemName"
                    :rules="[rules.requiredName]"
                  >
                  </v-text-field>
                  <v-text-field
                    dense
                    outlined
                    label="Description"
                    ref="description"
                    class="mx-5 px-2 required"
                    v-model="itemDescription"
                    :rules="[rules.requiredDes]"
                  >
                  </v-text-field
                ></v-row>
                <v-row> </v-row>
              </v-col>
              <v-row>
                <v-spacer />
                <v-btn
                  small
                  class=" px-1 mt-n5 mb-4 ml-10"
                  text
                  @click="dialogUpdate = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  small
                  class="blue  px-3 mt-n5 mb-4 ml-5 mr-10 white--text"
                  text
                  @click.stop="save"
                >
                  Save
                </v-btn>
              </v-row>
            </v-form></v-spacer
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>

<script>
import PageTitle from "../../components/PageTitle";
import * as axios from "axios";
import DialogMixins from "../../mixins/DialogMixins";
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import SnackBarMixins from "../../mixins/SnackBarMixins";
export default {
  name: "Products",
  components: {
    PageTitle,
    SnackBar: () => import(`../../components/SnackBar`)
  },
  data() {
    return {
      progress: false,
      dialog1: false,
      formHasErrors: false,

      dialogUpdate: false,
      description: "",
      phone: "",
      lng: "",
      lat: "",

      name: "",
      itemId: "",
      itemName: "",
      itemLat: "",
      itemLng: "",
      itemPhone: "",
      itemDescription: "",
      itemRegion: "",
      dialogDelete: false,
      region: "",
      location: "",
      productInfo: {},
      rules: {
        min: value => value.length >= 10 || "Must be 10 digits",
        requiredName: value => !!value || "Name is required.",
        requiredDes: value => !!value || "Description is Required."
      },
      loading: true,
      total: 0,
      options: {
        total: 0,
        page: 1,
        limit: 10
      }
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "",
          value: "",
          filterable: false,
          sortable: false
        },
        {
          text: "Name",
          align: "start",

          value: "name"
        },
        {
          text: "Price",
          align: "start",

          value: "prices"
        },
        { text: "Description", value: "description" }
      ];
    },
    ...mapGetters({
      productDetails: "products/getProductDetails",
      isListLoading: "getIsListLoading",
      isLoading: "getIsLoading",
      products: "products/getProductList",
      pagination: "products/getPagination"
    })
  },
  mixins: [DialogMixins, SnackBarMixins],
  filters: {
    firstLetterFunction(value) {
      return value.charAt(0).toUpperCase();
    }
  },
  watch: {
    options: debounce(function(payload) {
      this.$store.dispatch("products/list", {
        page: payload.page,
        itemsPerPage: payload.itemsPerPage
      });
    }, 500)
  },

  created() {
    this.$store.dispatch("products/list", {
      page: this.options.page,
      itemsPerPage: this.options.limit
    });
  },

  methods: {
    openDialog(value) {
      this.dialogUpdate = true;
      this.itemId = value.id;
      this.itemName = value.name;
      this.itemDescription = value.description;
    },

    deleteFunction(item) {
      this.dialogDelete = true;
      this.productInfo = item;
    },

    deleteProduct() {
      this.progress = true;
      setTimeout(() => {
        axios({
          method: "delete",
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`
          },
          url: `${process.env.VUE_APP_BASE}/products/${this.productInfo.id}`
        })
          .then(response => {
            //console.log(response);
            this.dialogDelete = false;
            this.progress = false;
            if (response.status === 200) {
              this.status = "success";
              this.message = response.data.message;
              //this.message = "Product has been deleted successfully.";
              this.showSnackBar = true;
              this.dialogUpdate = false;
              this.$store.dispatch("products/list", {
                page: this.options.page,
                itemsPerPage: this.options.limit
              });
            }
          })
          .catch(err => {
            if (err.response.status === 401) {
              this.$router.replace({ path: "/login" });
            } else {
              //console.log(err);
            }
          });
      }, 2000);
    },

    save() {
      this.progress = true;
      setTimeout(() => {
        axios({
          method: "put",
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`
          },
          url: `${process.env.VUE_APP_BASE}/products/${this.itemId}`,
          data: {
            name: this.itemName,
            description: this.itemDescription
          }
        })
          .then(response => {
            //console.log(response);
            this.progress = false;
            if (response.status === 200) {
              this.status = "success";
              this.message = response.data.message;
              this.showSnackBar = true;
              this.dialogUpdate = false;
              this.$store.dispatch("products/list", {
                page: this.options.page,
                itemsPerPage: this.options.limit
              });
            }
          })
          .catch(err => {
            if (err.status === 401) {
              this.$router.replace({ path: "/login" });
            } else {
              this.progress = false;
              this.status = "warning";
              this.showSnackBar = true;
              this.message = "Failed to update this product.";
            }
          });
      }, 5000);
    },

    add() {
      if (this.name !== "" && this.description !== "") {
        this.progress = true;
        setTimeout(() => {
          axios({
            method: "post",
            headers: {
              authorization: `Bearer ${localStorage.getItem("token")}`
            },
            url: `${process.env.VUE_APP_BASE}/products`,
            data: {
              name: this.name,
              description: this.description
            }
          })
            .then(response => {
              //console.log(response);
              this.getProducts(this.options.page, this.options.itemsPerPage);
              this.dialog1 = false;
              this.progress = false;
              if (response.status === 201) {
                this.status = "success";
                this.message = response.data.message;
                this.showSnackBar = true;
              }
            })
            .catch(err => {
              if (err.status === 401) {
                this.$router.replace({ path: "/login" });
              } else {
                this.progress = false;
                this.dialog1 = true;
                this.status = "warning";
                this.message = "Failed to add new product.";
                //console.log(err);
              }
            });
        }, 1000);
      } else {
        this.showSnackBar = true;
        this.status = "warning";
        this.message = "All fields required.";
      }
    }
  }
};
</script>

<style scoped>
.button {
  background-color: #04a9f3; /* Green */
  border: none;
  color: white;
  padding: 1px 4px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
}
#result .v-btn {
  min-width: 65px;
  width: 40px;
}
/* removes grey hover effect */
#result .v-btn::before {
  background-color: transparent;
}

/* adds icon scale hover effect */
#result .v-btn i:hover {
  transform: scale(1.15);
}

.buttonText {
  display: flex;
  flex-direction: row;
}
</style>
